header {
    border: 1px solid lightgray;
    box-shadow: 0 4px 12px lightgrey;
}

.pin-top-nav {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
}

.nav {
    padding: 5px 1% 2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

@media (max-width: 450px) {
    .nav {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

/* .nav-logo-link {
    display: inline-block;
    margin: 0 auto;
} */

.nav-bar_dynamic-open-app-btn,
.nav-bar_dynamic-open-app-btn:active {
    border: 1px solid lightgray;
    color: rgb(203,46,63);
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    transition-duration: 0.4s;
    background-color: white;
    font-weight: 600;
    border-radius: 4px;
}

.nav-bar_dynamic-open-app-btn:hover,
.nav-bar_dynamic-open-app-btn:focus {
    border: none;
    color: white;
    background-color: rgba(203,46,63,1);
    background-color: linear-gradient(180deg, rgba(203,46,63,1) 0%, rgba(240,73,58,1) 100%);
}


.nav-logo-link:visited,
.nav-logo-link:visited:active,
.nav-logo-link:visited:focus,
.nav-logo-link:visited:hover {
    color: #0073b1;
}

.sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
}

.nav-logo {
    height: 26px;
    width: 140px;
    margin-top: 8px;
}

@media (max-width: 768px) and (min-width: 451px) {
    .nav-logo {
        height: 22px;
        width: 120px;
        margin-top: 6px;
    }

    .nav-bar_dynamic-open-app-btn,
    .start-main-cnt_smpl-btn {
        font-size: 0.8rem;
        padding: 8px 16px;
    }
}
  
@media (max-width: 450px) and (min-width: 376px) {
    .nav-logo {
        height: 16px;
        width: 80px;
    }

    .nav-bar_dynamic-open-app-btn,
    .start-main-cnt_smpl-btn {
        font-size: 0.6rem;
        padding: 6px 12px;
    }
}
  
@media (max-width: 375px){
    .nav-logo {
        height: 13px;
        width: 60px;
        margin-top: 4px;
    }

    .nav-bar_dynamic-open-app-btn,
    .start-main-cnt_smpl-btn {
        font-size: 0.4rem;
        padding: 5px 10px;
    }
}