.header {
  padding: 0 12px;
  background: white;
}

.main {
  margin: 0 auto;
  /* padding-top: 16px; */
  display: -webkit-flex;
  display: -moz-stack;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  flex: 1;
}

.lazy-load, 
.lazy-loaded,
[data-delayed-url],
[data-delated-background] {
  -webkit-transition: opacity 334ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: opacity 334ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* TOAST BAR */
#toast-bar {
  visibility: hidden;
  min-width: 20%;
  max-width: 40%;
  background-color: lightgray;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  border-radius: 10px;
  padding: 14px;
  margin: 0 auto;
  font-size: 1.6rem;
}

#toast-bar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the toastbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 60px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 60px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 60px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 60px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@media (min-width: 900px) {
  .main {
    width: 790px;
  }
}

@media (max-width: 900px) and (min-width: 769px) {
  .main {
    width: 790px;
  }
}

@media (max-width: 768px) and (min-width: 451px) {
  .main {
    width: 660px;
  }

  #toast-bar {
    font-size: 1.3rem;
  }
}

@media (max-width: 450px) and (min-width: 376px) {
  .main {
    width: 390px;
  }

  #toast-bar {
    font-size: 0.78rem;
    padding: 10px;
  }
}

@media (max-width: 375px) and (min-width: 321px){
  .main {
    width: 340px;
  }

  #toast-bar {
    font-size: 0.68rem;
    padding: 8px;
  }
}

@media (max-width: 320px) {
  .main {
    width: 280px;
  }

  #toast-bar {
    font-size: 0.58rem;
    padding: 8px;
  }
}