.general-card-layout-info_entity-item-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 16px;
}

/* Style for linkedIn item  */
.general-card-layout-info_linkedin-item {
    background-color: #0e76a8;
}

.general-card-layout-info_linkedin-item h2,
.general-card-layout-info_linkedin-item a {
    color: white;
}

.general-card-layout-info_entity-item-cont:first-child {
    padding-top: 16px;
}

.general-card-layout-info_entity-item-cont:last-child {
    padding-bottom: 16px;
}

.general-card-layout-info_entity-item {
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
}

.general-card-layout-info_entity-item .general-card-layout-info_value {
    float: right;
}

.general-card-layout-info_label,
.general-card-layout-info_label-sublabel-cont {
    font-size: 1.2rem;
    font-weight: 600;
    color: rgba(0,0,0,0.9);
}

.general-card-layout-info_label-sublabel-cont .general-card-layout-info_label {
    font-size: 1.2rem;
}

.general-card-layout-info_value, 
.general-card-layout-info_entity-item-link {
    font-size: 1.2rem;
    font-weight: 400;
    color: rgba(0,0,0,0.9);
}

.general-card-layout-info_sub-label {
    font-size: 1rem;
    line-height: 0.8;
    font-weight: 400;
    color: gray;
}

.general-card-layout-info_entity-item-link {
    margin-left: 20px;
}

.general-card-layout-info_entity-item-link,
.general-card-layout-info_entity-item-link:visited,
.general-card-layout-info_entity-item-link:active {
    color: black;
    text-decoration: none;
}

.general-card-layout-info_entity-item-link:hover {
    color: #cb2e3f
}

.google-map-layout_entity-map {
    height: 300px;
}

.general-card-layout-info_social-icon {
    width: 32px;
    height: 32px;
    vertical-align: top;
}

@media (max-width: 768px) {
    .google-map-layout_entity-map {
        height: 240px;
    }

    .general-card-layout-info_label-sublabel-cont .general-card-layout-info_label {
        font-size: 1.2rem;
    }

    .general-card-layout-info_social-icon {
        width: 28px;
        height: 28px;
    }
}

@media (max-width: 420px) {
    .google-map-layout_entity-map {
        height: 100px
    }

    .general-card-layout-info_social-icon {
        width: 24px;
        height: 24px;
    }
}

.general-round-rectangle-layout {
    background-color: black;
    border: none;
    color: white;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 0 12px;
    border-radius: 10px;
}

@media (max-width: 450px) {
    .general-card-layout-info_label,
    .general-card-layout-info_label-sublabel-cont,
    .general-card-layout-info_value {
        font-size: 1rem;
    }

    .general-card-layout-info_entity-item-cont:first-child {
        padding-top: 10px;
    }
    
    .general-card-layout-info_entity-item-cont:last-child {
        padding-bottom: 10px;
    }

    .general-round-rectangle-layout {
        font-size: 0.7rem;
    }

    .google-map-layout_entity-map {
        height: 160px;
    }

    .general-card-layout-info_label-sublabel-cont .general-card-layout-info_label {
        font-size: 1rem;
    }
}

@media (max-width: 340px) {
    .general-card-layout-info_label,
    .general-card-layout-info_label-sublabel-cont,
    .general-card-layout-info_value {
        font-size: 0.8rem;
    }

    .general-card-layout-info_entity-item-cont:first-child {
        padding-top: 8px;
    }
    
    .general-card-layout-info_entity-item-cont:last-child {
        padding-bottom: 8px;
    }

    .general-round-rectangle-layout {
        font-size: 0.6rem;
        margin: 0 8px;
    }

    .general-card-layout-info_social-icon {
        width: 20px;
        height: 20px;
    }
}