.error-page-cnt {
    text-align: center;
    margin-top: 100px;
}

.error-page-cnt_entity-header {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 600;
    font-size: 2.4rem;
}

.error-page-cnt_entity-message {
    color: rgba(0, 0, 0, 0.9);
    font-size: 1.6rem;
    font-weight: 400;
}