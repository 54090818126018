.react-pdf__Page__canvas {
    margin: 0 auto;
}

.center_cnt {
    margin: 0 auto;
}

.reader-pdf-cnt {
    width: 100%;
    max-width: 1110px;
    display: flex;
    justify-content: center;
}

.react-pdf__Document {
    max-width: 100%;
    position: relative;
}

/* @media (max-width: 768px) and (min-width: 451px) {
    .react-pdf__Page__canvas {
        
    }
}
  
@media (max-width: 450px) and (min-width: 376px) {
    .react-pdf__Page__canvas {
        
    }
} */
  
@media (max-width: 375px) and (min-width: 321px){
    .react-pdf__Page,
    /* .react-pdf__Page__canvas, */
    .react-pdf__Page__textContent {
        width: 350px;
        /* height: 460px; */
    }
}
  
@media (max-width: 320px) {
    .react-pdf__Page,
    /* .react-pdf__Page__canvas, */
    .react-pdf__Page__textContent {
        width: 290px;
        /* height: 400px; */
    }
}