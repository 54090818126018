.start-nav-comp-wrapper-cnt img {
    opacity: 0;
}

.start-page-cnt {
    /* color: rgb(203,46,63); */
    /* color: linear-gradient(180deg, rgba(203,46,63,1) 0%, rgba(240,73,58,1) 100%);; */
    color: black;
    width: 100%;
    position: inherit;
}

.start-main-cnt {
    margin-top: 6%;
}

.start-main-cnt_smpl-btn-entity-cnt {
    margin: 0 auto;
}

.start-main-cnt_smpl-btn {
    border: none;
    color: white;
    background-color: rgba(203,46,63,1);
    background-color: linear-gradient(180deg, rgba(203,46,63,1) 0%, rgba(240,73,58,1) 100%);
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 1rem;
    cursor: pointer;
    transition-duration: 0.4s;
    font-weight: 600;
    border-radius: 4px;
}


/* STYLE SOCIAL */
#logo-header,
#objective-header {
    -ms-grid-column-align: center;
    justify-self: center;
}



#logo-header {
    width: 85px;
    /* 100 */
}

#objective-header {
    font-weight: normal;
    font-size: 2.7rem;
}

@media (max-width: 1024px) and (min-width: 769px) {
    #logo-header {
        width: 75px;
    }

    #objective-header {
        font-size: 2.5rem;
    }
}

 
@media (max-width: 768px) and (min-width: 451px) {
    #logo-header {
        width: 65px;
    }

    #objective-header {
        font-size: 2.2rem;
    }
}
  
@media (max-width: 450px) and (min-width: 376px) {
    #logo-header {
        width: 50px;
    }

    #objective-header {
        font-size: 1.5rem;
    }
}
  
@media (max-width: 375px) and (min-width: 321px){
    #logo-header {
        width: 44px;
    }

    #objective-header {
        font-size: 1.3rem;
    }
}
  
@media (max-width: 320px) {
    #logo-header {
        width: 38px;
    }

    #objective-header {
        font-size: 1.1rem;
    }
}