.grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-column: 1fr 1fr;
    grid-column: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    row-gap: 20px;
    column-gap: 20px;
}

.center-grid {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: stretch;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-areas: "." "Area";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 20px auto 20px auto;
    grid-template-rows: auto auto auto;
}


@media (max-width: 768px) and (min-width: 451px) {
    .grid {
        row-gap: 14px;
    }
}

@media (max-width: 450px) and (min-width: 376px) {
    .grid {
        row-gap: 10px;
    }
}
  
@media (max-width: 375px) and (min-width: 321px){
    .grid {
        row-gap: 8px;
    }
}
  
@media (max-width: 320px) {
    .grid {
        row-gap: 6px;
    }
}