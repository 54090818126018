/* CONFIGURE HEADER CARD SECTION */

.card-layout {
    border-radius: 20px;
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    -webkit-transition: -webkit-box-shadow 83ms;
    transition: -webkit-box-shadow 83ms;
    transition: box-shadow 83ms;
    transition: box-shadow 83ms, -webkit-box-shadow 83ms;
    background-color: white;
    width: 100%; /* Potential cause problem cuz not in layout template */
    margin: 50px 0;
    overflow: hidden;
}

.card-layout:first-child {
    margin-top: 0;
}

.card-layout:last-child {
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .card-layout {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media (max-width: 450px) {
    .card-layout {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}


/* COVER IMAGE */
.card-layout .cover-img {
    border-radius: 20px 20px 0 0;
    height: 200px;
}

@media (max-width: 450px) {
    .card-layout .cover-img {
        height: 120px;
    }
}

@media (max-width: 340px) {
    .card-layout .cover-img {
        height: 100px;
    }
}

.card-layout .cover-img::before {
    background: none;
}

.cover-img.lazy-loaded {
    background-image: url('../../Assets/Logos/j.image.default.background.cover.jpg');
}

.cover-img {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.cover-img::before {
    content: '';
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 100;
    pointer-events: none;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0,0,0,0.55)));
    background: linear-gradient(transparent, rgba(0,0,0,0.55));
}

.lazy-loaded {
    opacity: 1;
}

/* PROFILE IMAGE AND INFO LABEL */
.header-card-layout_info {
    padding: 10px;
    position: relative;
    display: inline-flex;
}

.header-card-layout_cont-img {
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.15), 0 2px 3px rgba(0,0,0,0.2);
    -webkit-transition: -webkit-box-shadow 83ms;
    transition: -webkit-box-shadow 83ms;
    transition: box-shadow 83ms;
    transition: box-shadow 83ms, -webkit-box-shadow 83ms;
    position: relative;
}

.deco-cont-img {
    display: inline-block;
}

.deco-cont-img_circle {
    width: 118px;
    height: 118px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-clip: content-box;
    border: 0px solid rgba(0,0,0,0);
    border-radius: 50%;
}

.header-card-layout_cont-img {
    border: 4px solid white;
    margin: 0 12px;
}

/* HEADER INFO LABEL */
.header-card-layout_cont-info {
    padding: 20px;
}

.header-card-layout_title {
    font-size: 1.8rem;
    line-height: 1.3333333;
    font-weight: 600;
    color: rgba(0,0,0,0.9);
    margin-bottom: 0;
}

.header-card-layout_headline {
    font-size: 1.2rem;
    line-height: 1.2;
    font-weight: 400;
    color: rgba(0,0,0,0.9);
}

@media (max-width: 450px) {
    .deco-cont-img_circle {
      width: 75px;
      height: 75px;
    }

    .header-card-layout_info {
        padding: 5px 10px;
    }

    .header-card-layout_cont-info {
        padding: 10px;
    }

    .header-card-layout_title {
        font-size: 1.3rem
    }

    .header-card-layout_headline {
        font-size: 0.8rem;
    }
}

@media (max-width: 340px) {
    .deco-cont-img_circle {
        width: 60px;
        height: 60px;
    }

    .header-card-layout_cont-img {
        margin: 0 6px;
    }

    .header-card-layout_title {
        font-size: 1.1rem;
    }
    
    .header-card-layout_headline {
        font-size: 0.6rem;
    }
}


.card-layout_entity-map-cnt {
    overflow: hidden;
}

.quote-section-layout_entity-image {
    width: 35px;
    height: 40px;
}

.quote-section-layout_entity-message {
    color: rgba(0,0,0,0.9);
    font-size: 1.4rem;
    font-weight: 500;
}

@media (max-width: 450px) {
    .quote-section-layout_entity-image {
        width: 25px;
        height: 30px;
    }

    .quote-section-layout_entity-message {
        font-size: 1rem;
    }
}