#spinner-indicator-layout-cnt {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 5;
    margin: -75px 0 0 -75px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #cb2e3f;
    border-left: 16px solid #cb2e3f;
    border-bottom: 16px solid #cb2e3f;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1.2s linear infinite;
    animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}