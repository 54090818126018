/* Base line css style */
html {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
  font-family: 'NunitoSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/* body, html {
  height: 100%;
  width: 100%;
} */

body {
  background-color: white;
  color: black;
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
}

/* #root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: yellow;
  color: black;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* FONT DECLARATION */

/* Extra Light */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Light */ 
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Regular */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Semi-Bold */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: oblique;
}

/* Bold */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: oblique;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: oblique;
}

/* Extra-Bold */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Black */
@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'NunitoSans';
  src: url('./Assets/Fonts/NunitoSans/NunitoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}