.phone-preview-cnt {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-image: url("../../Assets//Images/iphone_rose_gold_frame_template.png");
    background-size: 300px 450px;
    background-repeat: no-repeat;
    background-position: 0 0;
    -webkit-flex-basis: 300px;
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
    height: 450px;
    width: 300px;
    margin: 0 auto;
}

/* 330 484 */
/* 13px 0 0 93px */

.phone-preview-cnt_img-entity {
    position: relative;
    margin: 12px 0 0 85px;
}

.phone-preview-cnt_img-content {
    height: 426px;
    width: 202px;
    border-radius: 20px;
}

/* 428 201 */

.slide-container {
    height: 426px;
    width: 202px;
}


@media (max-width: 768px) and (min-width: 451px) {
    .phone-preview-cnt {
        background-size: 260px 380px;
        -webkit-flex-basis: 260px;
        -ms-flex-preferred-size: 260px;
        flex-basis: 260px;
        height: 380px;
        width: 260px;
    }

    .phone-preview-cnt_img-content {
        height: 364px;
        width: 176px;
    }

    .slide-container {
        height: 364px;
        width: 176px;
    }

    .phone-preview-cnt_img-entity {
        margin: 9px 0 0 73px;
    }
}
  
@media (max-width: 450px) and (min-width: 376px) {
    .phone-preview-cnt {
        background-size: 206px 300px;
        -webkit-flex-basis: 206px;
        -ms-flex-preferred-size: 206px;
        flex-basis: 206px;
        height: 300px;
        width: 206px;
    }

    .phone-preview-cnt_img-content {
        height: 284px;
        width: 139px;
        border-radius: 14px;
    }

    .slide-container {
        height: 284px;
        width: 139px;
    }

    .phone-preview-cnt_img-entity {
        margin: 8px 0 0 58px;
    }
}
  
@media (max-width: 375px) and (min-width: 321px){
    .phone-preview-cnt {
        background-size: 170px 240px;
        -webkit-flex-basis: 170px;
        -ms-flex-preferred-size: 170px;
        flex-basis: 170px;
        height: 240px;
        width: 170px;
    }

    .phone-preview-cnt_img-content {
        height: 228px;
        width: 115px;
        border-radius: 11px;
    }

    .slide-container {
        height: 228px;
        width: 115px;
    }

    .phone-preview-cnt_img-entity {
        margin: 6px 0 0 48px;
    }
}
  
@media (max-width: 320px) {
    .phone-preview-cnt {
        background-size: 128px 180px;
        -webkit-flex-basis: 128px;
        -ms-flex-preferred-size: 128px;
        flex-basis: 128px;
        height: 180px;
        width: 128px;
    }

    .phone-preview-cnt_img-content {
        height: 172px;
        width: 87px;
        border-radius: 9px;
    }

    .slide-container {
        height: 172px;
        width: 87px;
    }

    .phone-preview-cnt_img-entity {
        margin: 4px 0 0 36px;
    }
}