.main-footer-cnt {
    margin-top: 80px;
}

@media (max-width: 450px) {
    .main-footer-cnt {
        margin-top: 50px;
    }
}

.pin-bottom {
    position: fixed;
    bottom: 60px;
    left: 0;
    right: 0;
    z-index: 90;
}

.pin-bottom-20 {
    bottom: 20px;
}

.main-footer-cnt_appstore-image {
    float: right;
}

#appstore-header {
    width: 160px;
}

#main-footer-cnt_copyright-header {
    font-size: 1.1rem;
}

@media (max-width: 768px) and (min-width: 451px) {
    #appstore-header {
        width: 130px;
    }

    #main-footer-cnt_copyright-header {
        font-size: 1rem;
    }
}
  
@media (max-width: 450px) and (min-width: 376px) {
    #appstore-header {
        width: 90px;
    }
    #main-footer-cnt_copyright-header {
        font-size: 0.7rem;
    }
}
  
@media (max-width: 375px) and (min-width: 321px){
    #appstore-header {
        width: 80px;
    }

    #main-footer-cnt_copyright-header {
        font-size: 0.6rem;
    }
}
  
@media (max-width: 320px) {
    #appstore-header {
        width: 70px;
    }

    #main-footer-cnt_copyright-header {
        font-size: 0.5rem;
    }
}

.general-link-entity {
    text-decoration: none;
}

.no-margin-cnt-entity {
    margin: 0;
}